
import { Vue, Component } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiConfigData, apiConfigEdit } from '@/api/config'

@Component({
  components: {
    MaterialSelect
  }
})
export default class config extends Vue {
  /** S Data **/
  // 表单数据
  form: any = {
    equity_sharing1: '', // 分润1
    equity_sharing2: '', // 分润2
    equity_sharing3: '', // 分润3
    equity_consume_sharing1: '', // 消费额1
    equity_consume_sharing2: '', // 消费额2
    equity_consume_sharing3: '', // 消费额3
    direct_push: '', // 直推
    small1: '',
    small2: '',
    small3: '',
    small4: '',
    province: '',
    city: '',
    district_county: '',
    partner: '',
    yunzhi: '',
    consume_multiple: '',
    commission: '',
    equity_day: ''
  }

  // 表单验证
  // eslint-disable-next-line @typescript-eslint/ban-types
  rules: object = {
    equity_sharing1: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
    equity_sharing2: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
    equity_sharing3: [{ required: true, message: '必填项不能为空', trigger: 'blur' }]
  }

  /** E Data **/

  /** S Methods **/
  // 初始化表单数据
  initFormData () {
    apiConfigData().then(res => {
      // 表单同步于接口响应数据
      for (const key in res) {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.form.hasOwnProperty(key)) {
          continue
        }
        this.form[key] = res[key]
      }
    })
      .catch(() => {
        this.$message.error('数据加载失败，请刷新重载')
      })
  }

  // 重置表单数据
  // onResetFrom() {
  //   this.initFormData()
  //   this.$message.info('已重置数据')
  // }

  // 提交表单
  onSubmitFrom (formName: string) {
    const refs = this.$refs[formName] as HTMLFormElement
    console.log(refs.validate((valid: boolean): boolean => valid))

    refs.validate((valid: boolean) => {
      if (!valid) {
        return this.$message.error('请完善信息')
      }
      const loading = this.$loading({ text: '保存中' })
      const params: any = { ...this.form }

      delete params.url
      delete params.business_domain
      delete params.js_secure_domain
      delete params.web_auth_domain

      apiConfigEdit({
        ...params
      })
        .then(() => {
          this.$message.success('保存成功')
        })
        .catch(() => {
          this.$message.error('保存失败')
        })
        .finally(() => {
          loading.close()
        })
    })
  }

  /** E Methods **/

  /** S Life Cycle **/
  created () {
    this.initFormData()
  }

  /** E Life Cycle **/
}
